import React, { Component } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

import {
  medWrapper,
  headlineThree,
  headlineTwo,
  colors,
  bodyCopy,
  buttonOne,
} from "../Utilities"
import FlyPicker from "./FlyPicker"
import LinePicker from "./LinePicker"
import PostCard from "./PostCard"

const AllPostsSection = styled.section`
  position: relative;
  padding-top: 10rem;
  padding-bottom: 5rem;

  .all-posts-wrapper {
    ${medWrapper};
    justify-content: flex-start;
  }

  .all-posts-title {
    position: relative;
    width: 100%;
    text-align: center;

    h2 {
      span {
        display: block;
        text-transform: uppercase;
      }

      span {
        ${headlineThree};
        color: ${colors.colorSecondary};
      }
    }
    p {
      ${bodyCopy};
    }
  }

  .all-post-more-btn {
    width: 100%;
    margin: 2.5rem auto;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

class AllPosts extends Component {
  constructor(props) {
    super(props)

    this.loadMorePosts = this.loadMorePosts.bind(this)
    this.checkForMore = this.checkForMore.bind(this)

    this.state = {
      allPosts: [],
      currentShow: [],
      noMorePosts: false,
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        allPosts: this.props.data.posts.edges,
        currentShow: this.props.data.posts.edges.slice(0, 6),
      }
    }, this.checkForMore)
  }

  checkForMore() {
    if (this.state.allPosts.length <= this.state.currentShow.length) {
      this.setState(prevState => {
        return {
          ...prevState,
          noMorePosts: true,
        }
      })
    }
  }

  loadMorePosts() {
    this.setState(prevState => {
      return {
        ...prevState,
        currentShow: this.state.allPosts.slice(
          0,
          prevState.currentShow.length + 3
        ),
      }
    }, this.checkForMore)
  }

  render() {
    const { info } = this.props
    return (
      <AllPostsSection>
        <div className="all-posts-wrapper">
          <div className="all-posts-title">
            <h2>
              <span>{info.titleTop}</span>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: info.titleContent }} />
            <FlyPicker info={info.flyPicker} />
          </div>
          {this.state.currentShow.map((post, index) => {
            return <PostCard key={index} post={post.node} index={index} />
          })}
          <div className="all-post-more-btn">
            <button
              disabled={this.state.noMorePosts}
              onClick={this.loadMorePosts}
            >
              {this.state.noMorePosts ? "no more posts" : "Load More"}
            </button>
          </div>
        </div>
        <LinePicker info="three" />
      </AllPostsSection>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query allPostsQuery {
        posts: allWordpressPost(sort: { order: DESC, fields: date }) {
          edges {
            node {
              date(formatString: "MMMM D, Y")
              title
              slug
              wordpress_id
              acf {
                _dow_author
                _dow_featured_image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <AllPosts data={data} {...props} />}
  />
)
