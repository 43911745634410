import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import AllPosts from "../components/AllPosts"
import AllGear from "../components/AllGear"

const Flybox = props => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const blogIntro = {}
  blogIntro.titleTop = props.data.page.acf._dow_blog_intro_title_top
  blogIntro.titleContent = props.data.page.acf._dow_blog_intro_content
  blogIntro.flyPicker = props.data.page.acf._dow_blog_intro_fly_picker

  const gearInfo = {}
  gearInfo.title = props.data.page.acf._dow_gear_title
  gearInfo.intro = props.data.page.acf._dow_gear_intro

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      <AllPosts info={blogIntro} />
      <AllGear info={gearInfo} />
    </Layout>
  )
}

export const query = graphql`
  query flyboxPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_blog_intro_title_top
        _dow_blog_intro_content
        _dow_blog_intro_fly_picker

        _dow_gear_title
        _dow_gear_intro
      }
    }
  }
`

export default Flybox
