import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import {
  medWrapper,
  headlineTwo,
  fontSizer,
  colors,
  bodyCopy,
} from "../Utilities"

const getData = graphql`
  query allGear {
    gear: allWordpressWpGear {
      edges {
        node {
          wordpress_id
          acf {
            _dow_gear_name
            _dow_gear_description
            _dow_gear_link_req
            _dow_gear_url
            _dow_gear_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const GearSection = styled.section`
  @media (min-width: 1025px) {
    padding: 5rem 0;
  }

  .gear-wrapper {
    ${medWrapper};
  }
  .main-gear-title {
    width: 100%;
    margin: 2rem auto;
    text-align: center;

    h2 {
      ${headlineTwo};
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
    }
  }
`

const GearCard = styled.div`
  width: calc(100% - 4rem);
  margin: 2rem;

  @media (min-width: 768px) {
    width: calc(33.33% - 4rem);
    margin: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(25% - 4rem);
    margin: 2rem;
  }

  .gear-image-container {
    display: block;
    position: relative;
    width: 18.5rem;
    height: 18.5rem;
    margin: 0 auto;
    padding: 0.8rem;
    border: solid 0.3rem ${colors.colorAccent};
    border-radius: 50%;
    overflow: hidden;
    z-index: 500;

    .gear-link-hover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      bottom: 0.8rem;
      left: 0.8rem;
      border-radius: 50%;
      transition: all 0.3s ease;
      opacity: 0;

      .gear-link-hover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${colors.colorAccent};
        border-radius: 50%;
        opacity: 0.53;
      }

      p {
        position: relative;
        margin: 0;
        color: ${colors.white};
        text-transform: uppercase;
        z-index: 5;

        span {
          display: block;
        }
      }
    }

    .gear-image-inner-container {
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &:hover {
      .gear-link-hover {
        opacity: 1;
      }
    }
  }

  .gear-meta-info {
    text-align: center;
    h2 {
      ${bodyCopy};
      font-weight: bold;
    }
    p {
      ${fontSizer(1.2, 1.4, 76.8, 160, 1.6)}
      color: ${colors.black};
      font-style: italic;
      z-index: 5;
    }
  }
`

const AllGear = ({ info }) => {
  const data = useStaticQuery(getData)
  const {
    gear: { edges: gear },
  } = data
  return (
    <GearSection>
      <div className="gear-wrapper">
        <div className="main-gear-title">
          <h2>{info.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: info.intro }} />
        </div>
        {gear.map(item => {
          return (
            <GearCard key={item.node.wordpress_id}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={item.node.acf._dow_gear_url}
                className="gear-image-container"
              >
                <div className="gear-image-inner-container">
                  <Img
                    fluid={
                      item.node.acf._dow_gear_image.localFile.childImageSharp
                        .fluid
                    }
                    alt={item.node.acf._dow_gear_image.alt_text}
                  />
                </div>
                <div className="gear-link-hover">
                  <p>
                    Shop <span>Gear</span>
                  </p>
                  <div className="gear-link-hover-overlay" />
                </div>
              </a>
              <div className="gear-meta-info">
                <h2>{item.node.acf._dow_gear_name}</h2>
                <div>
                  <p>{item.node.acf._dow_gear_description}</p>
                </div>
              </div>
            </GearCard>
          )
        })}
      </div>
    </GearSection>
  )
}

export default AllGear
